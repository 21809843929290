import { http } from '../api';
import qs from 'qs';
import { ServiceAgent, ServiceAgentResponse } from '../model/Services';

const controller = 'scservice';

type ServiceCreate = {
  activityId: string,
  quantity: number,
  operationId: number,
  location: string,
  priority: number,
  client: string,
  description: string
  triggerActivities?: Array<string>
}

type ServicePendingResponse = {
  id: string;
  bed?: string;
  quantity: number;
  activity: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  department: {
    id: string;
    name: string;
  };
  priority: number;
  client?: string;
  origin?: string;
  description?: string;
  slaStatus: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
  interruptTimestamp?: Date;
  cancelTimestamp?: Date;
  sla?: {
    toEnd: number;
  };
  cancelReason?: string;
  interruptReason?: string;
  agent?: {
    name: string;
    job?: string;
    photo?: string;
  };
};

export type ServicePending = {
  id: string;
  bed?: string;
  quantity: number;
  activity: string;
  category: string;
  department: string;
  departmentId: string;
  priority: number;
  client?: string;
  origin?: string;
  description?: string;
  slaStatus: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
  interruptTimestamp?: Date;
  cancelTimestamp?: Date;
  sla?: {
    toEnd: number;
  };
  cancelReason?: string;
  interruptReason?: string;
  agent?: {
    name: string;
    job?: string;
    photo?: string;
  };
};

export interface IRelatoryFilters {
  [key: string]: string | number | Date[] | number[] | string[] | undefined | null;
  attendance?: string | null;
  nm_paciente?: string | null;
  activityId?: string | null;
  requestingAgent?: string;
  requestTimestamp?: Date[] | string[] | null;
  startTimestamp?: string;
  approveTimestamp?: Date[];
  acceptTimestamp?: string;
  endTimestamp?: string;
  cancelTimestamp?: string;
  suspendTimestamp?: string;
  rating?: number | null;
  slaStatus?: string | null;
  slaStatusExceed?: string | null;
  slaStartSuspensionTimestamp?: string;
  slaTotalSuspensionTime?: number;
  createAt?: string;
  bed?: string | null;
  alocatedAgent?: string | null;
}

export type ReportResponse = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Report[];
};

export type Report = {
  startDate: string;
  endDate: string;
  bed?: string;
  slaExceeded?: string;
  department?: string;
  category?: string;
  activity?: string;
  status?: string;
  enabled: boolean;
};

export type Relatory = {
  id: string;
  withDeleted: boolean;
  requestingAgent: string;
  attendanceId: string;
  dt_nascimento: string;
  nm_paciente: string;
  ds_leito: string;
  cd_leito: string;
  unidade_internacao: string;
  bed: string;
  activityId: string;
  categoryId: string;
  requestTimestamp: string;
  startTimestamp: Date;
  approveTimestamp: Date;
  rejectTimestamp: Date;
  acceptTimestamp: Date;
  endTimestamp: string;
  archiveTimestamp: Date;
  cancelTimestamp: Date;
  slaAcceptTimeExceded: string;
  slaStartTimeExceded: string;
  slaEndTimeExceded: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  rating: number | number[];
  companyId: string;
  slaStatus: string;
  badReviews?: boolean;
};
export async function create(service: ServiceCreate): Promise<void> {
  await http.post(`${controller}`, service);
}

export async function getRelatory(payload: IRelatoryFilters): Promise<ReportResponse> {
  let params = qs.stringify(
    {
      ...payload,
      order: 'DESC',
      withDeleted: true,
    },
    { indices: false, skipNulls: true }
  );

  if (payload.slaStatus) {
    params = qs.stringify(
      {
        ...payload,
        order: 'DESC',
        withDeleted: true,
      },
      { indices: false, skipNulls: true }
    );
  }

  const { data } = await http.get<ReportResponse>(`${controller}/getReport?${params}`);

  const items = data.data.map((d) => {
    return {
      ...d,
      slaExceeded: getSlaText(d.slaExceeded ?? ''),
    };
  });

  return {
    ...data,
    data: items,
  };
}

function getSlaText(input: string) {
  switch (input) {
    case 'exceded':
      return 'Excedido';
    case 'normal':
      return 'Normal';
    case 'warning':
      return 'Quase Excedido';
    default:
      return '';
  }
}

export async function getServicesPending(): Promise<ServicePending[]> {
  const { data } = await http.get<ServicePendingResponse[]>(`${controller}/recent`);

  return data.map((e) => {
    return {
      id: e.id,
      endTimestamp: e.endTimestamp ? new Date(e.endTimestamp) : undefined,
      bed: e.bed,
      slaStatus: e.slaStatus,
      departmentId: e.department.id,
      department: e.department.name,
      origin: e.origin,
      quantity: e.quantity,
      priority: e.priority,
      description: e.description,
      client: e.client,
      activity: e.activity.name,
      category: e.category.name,
      startTimestamp: e.startTimestamp ? new Date(e.startTimestamp) : undefined,
      interruptTimestamp: e.interruptTimestamp ? new Date(e.interruptTimestamp) : undefined,
      cancelTimestamp: e.cancelTimestamp ? new Date(e.cancelTimestamp) : undefined,
      sla: e.sla,
      cancelReason: e.cancelReason,
      interruptReason: e.interruptReason,
      agent: e.agent ? {
        name: e.agent.name,
        job: e.agent.job,
        photo: e.agent.photo,
      } : undefined,
    };
  });
}

export async function getServicesAgent(agentId: string): Promise<ServiceAgent[]> {

  try {
    const { data } = await http.get<ServiceAgentResponse>(`${controller}/agent/${agentId}?page=1&limit=20`);

    return data.data.map(service => ({
      id: service.id,
      startDate: new Date(service.startDate),
      endDate: service.endDate ? new Date(service.endDate) : null,
      interruptTimestamp: service.interruptTimestamp ? new Date(service.interruptTimestamp) : undefined,
      cancelTimestamp: service.cancelTimestamp ? new Date(service.cancelTimestamp) : undefined,
      sla: service.sla,
      cancelReason: service.cancelReason,
      interruptReason: service.interruptReason,
      unity: service.unity,
      bed: service.bed,
      slaExceeded: service.slaExceeded,
      department: service.department,
      category: service.category,
      activity: service.activity,
      status: service.status,
      attendance: service.attendance,
      origin: service.origin,
      description: service.description,
      quantity: service.quantity,
      priority: service.priority ?? 1,
    }));
  } catch (error) {
    console.error('Erro ao buscar os serviços do agente:', error);
    throw error;
  }
}

export async function interruptService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/interrupt/${id}`, { reason });
  } catch (error) {
    throw new Error('Erro ao interromper o serviço: ' + error);
  }
}

export async function cancelService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/cancel/${id}`, { cancelReason: reason });
  } catch (error) {
    throw new Error('Erro ao cancelar o serviço: ' + error);
  }
}

export async function retakeService(id: string, agentId?: string): Promise<void> {
  try {
    const data: { agentId?: string } = {};

    if (agentId) {
      data.agentId = agentId;
    }

    await http.post(`${controller}/retake/${id}`, data);
  } catch (error) {
    throw new Error('Erro ao retomar o serviço: ' + error);
  }
}

