import { http } from '../api';
import { Attendance } from '../model/Attendance';
import { Filters, PaginateResponse } from '../model';
import { Accomodation } from '../model/Accomodation';

const controller = '/attendance';

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<Accomodation>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Accomodation> | any>(`${controller}/open`, {
    params,
  });

  return { page: data.page, total: data.total ? data.total : data.length , data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };

}

export async function getAllAttendances(): Promise<Attendance[]> {
  const response = await http.get(controller);
  return response.data;
}

export async function getAttendanceByName(attendanceId: string): Promise<Accomodation[]> {
  const response = await http.get<Accomodation[]>(`${controller}/${attendanceId}`);
  return response.data;
}
